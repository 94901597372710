<template>
  <div>
    <ViewTemplateReportPreview
      :panel="panel"
      :templateList="templateList"
      :templateReport="templateReport"
    />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportAuditView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Adm",
        title: "Auditoria",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/AuditOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "description",
            title: "Descrição",
            type: "text",
            orderBy: "asc",
            fieldComparison: "actionName",
            selected: true,
            requiredOData: false,
            /* classCssBody: [
              {
                classCss: "primary",
                fieldComparison: "Adicionar",
              },
              { classCss: "edit", fieldComparison: "Modificar" },
              { classCss: "danger", fieldComparison: "Remover" },
              { classCss: "info", fieldComparison: "Login" },
            ],*/
          },
          {
            field: "actionName",
            title: "Ação",
            type: "text",
            classCssTitle: "text-center",
            fieldComparison: "actionName",
            requiredOData: true,
            selected: true,
            classCssBody: "text-center",
          },
          {
            field: "registeredUser",
            title: "Usuário",
            type: "text",
            requiredOData: false,
            selected: true,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "registeredDate",
            title: "Data",
            type: "dateTime",
            requiredOData: false,
            selected: true,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
        rules: [
          {
            name: "registeredDate",
            title: "Período",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
            },
            notDuplicate: true,
            isRequired: true,
          },
          {
            name: "description",
            title: "Descrição",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "action",
            title: "Ação",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Login", value: 4 },
              { title: "Adicionar", value: 1 },
              { title: "Modificar", value: 2 },
              { title: "Remover", value: 3 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "registeredUser",
            title: "Usuário",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          /*           
          {
            icon: "fas fa-database",
            type: "text",
            type: "field",
            id: "user",
            label: "Usuário",
            valida: true,
            obrigatorio: false,
          },
            {
                icon: "fas fa-sigma",
                type: "checkbox",
                type: "resumo",
                id: "despesa",
                label: "Despesa",
                classeCss: "danger",
                typeField: "value",
                choices: [
                  {
                    label: "Soma",
                    value: "sum",
                    como: {
                      title: "Soma Despesa",
                      value: "somaDespesa",
                    },
                  },
                  {
                    label: "Média",
                    value: "average",
                    como: {
                      title: "Média Despesa",
                      value: "mediaDespesa",
                    },
                  },
                  {
                    label: "Máximo",
                    value: "max",
                    como: {
                      title: "Máximo Despesa",
                      value: "maximoDespesa",
                    },
                  },
                ],
                valida: true,
              },
              {
                icon: "fas fa-sigma",
                type: "checkbox",
                id: "receita",
                type: "resumo",
                label: "Receita",
                classeCss: "primary",
                typeField: "value",
                choices: [
                  {
                    label: "Soma",
                    value: "sum",
                    como: {
                      title: "Soma Receita",
                      value: "somaReceita",
                    },
                  },
                  {
                    label: "Média",
                    value: "average",
                    como: {
                      title: "Média Receita",
                      value: "mediaReceita",
                    },
                  },
                  {
                    label: "Máximo",
                    value: "max",
                    como: {
                      title: "Máximo Receita",
                      value: "maximoReceita",
                    },
                  },
                ],
                valida: true,
              },*/
        ],
      },
    };
  },
};
</script>
